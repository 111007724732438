import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Hi! My name is Maciej.`}</h3>
    <p>{`I'm PHP Programmer, system architect, DDD and CQRS/ES enthusiast, Event Storming facilitator and Value Objects lover.`}</p>
    <div style={{
      "textAlign": "right"
    }}>Learner / Individualization / Strategic / Analytical / Maximizer</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      